import React, { useState,useEffect ,useId} from 'react';
import Navbar from './nav';
import Footer from './footer';
import Modal from 'react-modal';
import './log2.css';
import { SlArrowLeft,SlArrowRight  } from "react-icons/sl";
import { FaAddressCard } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";
import { FaRegHourglass } from "react-icons/fa";
import { PiX } from 'react-icons/pi';
import { useLocation } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Request = () => {


  // Separate state for each modal form
  const [modal1Data, setModal1Data] = useState({ email: '', mobile: '' ,firstname:'',lastname:'',quantity: '', enddate: '' ,id: '', address: '', city: '',pin:'', orderid: '', position: '' });

 const nav1=useNavigate();
// Get data from localStorage
const savedLoadTypes = localStorage.getItem('loadTypes');

// Parse the JSON string back to an array (if it exists)
const loadTypesArray = savedLoadTypes ? JSON.parse(savedLoadTypes) : [];
  // Handlers for each modal
  const handleModal1Change = (e) => {
    const { name, value } = e.target;
    setModal1Data((prev) => ({ ...prev, [name]: value }));
  };

  function showLoadingAlert() {
    const alertBox = document.getElementById('custom-alert');
    alertBox.style.display = 'block'; // Show the alert
   


    const alertBox1 = document.getElementById('form1');
    alertBox1.style.display = 'none'; // Show the alert
    setTimeout(() => {
        alertBox.style.display = 'none'; 
        alertBox1.style.display = 'block';// Hide the alert
    }, 3000);
}

  // Submission handlers for each modal
  const handleSubmitModal1 = (e) => {
    
    e.preventDefault();
   
    showLoadingAlert();
    console.log('Modal 1 Data:', modal1Data);

    fetch('https://framefista.com/api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modal1Data),
    })
    .then(response => response.json())
    .then(data => {
    
      if (data.error) {
        console.error("Error:", data.error);
        alert("aman",data.error)
      } else {
        alert('Order Submitted: ' + data.message);

        nav1("/");
        console.log("Success:", data.message);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      alert(error)
    });

  };


  // Find the item with id 2
const query = useQuery();
const id = query.get('id');
const orderid = `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;


useEffect(() => {
  if (id) {
    setModal1Data((prevData) => ({
      ...prevData,
      id: id, // Set the id from query parameter
    }));
  }
  if (orderid) {
    setModal1Data((prevData) => ({
      ...prevData,
      orderid: orderid, // Set the id from query parameter
    }));
  }
}, [id]);



const quantity = query.get('quantity');

useEffect(() => {
  if (quantity) {
    setModal1Data((prevData) => ({
      ...prevData,
      quantity: quantity, // Set the id from query parameter
    }));
  }
}, [quantity]);



const itemWithId2 = loadTypesArray.find(item => item.id == parseInt(id, 10));


   {/* console.log(`ID: ${itemWithId2.id}`);
    console.log(`Name: ${itemWithId2.name}`);
    console.log(`Details: ${itemWithId2.details}`);
    console.log(`Load: ${itemWithId2.load}`);
    console.log(`Price: ${itemWithId2.price}`);
    console.log(`Power: ${itemWithId2.power}`);
    console.log(`Image: ${itemWithId2.image}`);*/}

    const name = itemWithId2.name
    const image = itemWithId2.image
    const load= itemWithId2.load
    const price= itemWithId2.price
    const details=itemWithId2.details

  return (
    <div  style={{background:"rgb(255,253,245)"}}>
      
    
      < Navbar  />
      
    
    
      
    <div className='imagegridrequest' class='w-[80%] mt-[12%] lg:mt-[5%] pt-[4%] ml-[5%] ' >
      <div>
      
      <p class="h-[50%] w-[100%]" id="custom-alert" style={{display:'None'}}>Loading...</p>
         <p class=" font-semibold text-xl flex flex-row">Delivery Information  <img class="w-10 h-7 ml-" src={image}/></p>
         
        <h3><p class=" text-red-500"> Total={parseInt(price)*parseInt(quantity)+100} Rs  (delhivery 100 included)</p></h3>
        <form  id="form1" onSubmit={handleSubmitModal1}>
          <div className="imagegridrequest">

       
            <label>Address</label>
            <input className="login__input"
              type="text"
              name="email"
              value={modal1Data.email}
              onChange={handleModal1Change}
              required
            />
          </div>
          
          <div className="imagegridrequest">
            <label>Mobile</label>
            <input className="login__input"
              type="number"
              name="mobile"
              value={modal1Data.mobile}
              onChange={handleModal1Change}
              required
            />
          </div>
          <div className="imagegridrequest">
            <label>Name</label>
            <input className="login__input"
              type="text"
              name="firstname"
              value={modal1Data.firstname}
              onChange={handleModal1Change}
              required
            />           
          </div>


     
          <div className="imagegridrequest">
            <label>Address</label>
            <input className="login__input"
              type="text"
              name="address"
              value={modal1Data.address}
              onChange={handleModal1Change}
              required
            />
          </div>
          <div className="imagegridrequest">
            <label>City</label>
            <input className="login__input"
              type="text"
              name="city"
              value={modal1Data.city}
              onChange={handleModal1Change}
              required
            />
          </div>


          <div className="imagegridrequest">
            <label>Pin Code</label>
            <input className="login__input"
              type="number"
              name="pin"
              value={modal1Data.pin}
              onChange={handleModal1Change}
              required
            />

         
          </div>

          <div  class="flex flex-row  ml-[5%] lg:ml-[7%]">
            <label className=' '>Quantity :-</label>
            <label>{modal1Data.quantity}</label>

            </div>

          <input  class="w-[50%]" className="login__submit"  type="submit"/>

           <h2>Currently only Support Cash on Delivery</h2>

        

         
        </form>
      
      </div>
      
    </div>
   
      
    <Footer />
    </div>
  );
};

export default Request;
