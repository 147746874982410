// src/components/LandingPage.js

import React, { useState, useEffect,useRef  } from 'react';
import './log.css';
import Navbar from './nav';
import Footer from './footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import stringSimilarity from 'string-similarity';
import { useLocation } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ConfettiExplosion from 'react-confetti-explosion';
import { FaWhatsapp } from "react-icons/fa";
const testimonials = [
  { id: 1, name: "John Doe", feedback: "Great service!" },
  { id: 2, name: "Jane Smith", feedback: "Excellent quality!" },
  { id: 3, name: "Alice Brown", feedback: "Highly recommended!" },
  // Add more testimonials as needed
];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const smallProps = {
  force: 0.4,
  duration: 2200,
  particleCount: 60,
  width: 600,
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black", marginRight: "0%", zIndex: 10 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black", marginLeft: "0%", zIndex: 10 }}
      onClick={onClick}
    />
  );
}


function SampleNextArrow1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: 'blue', background: "black", marginRight: "0%", zIndex: 10 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black", marginLeft: "0%", zIndex: 10 }}
      onClick={onClick}
    />
  );
}


const LandingPage = () => {


     const [isSmallExploding, setIsSmallExploding] = useState(false);
     
     const intervalRef = useRef(null);
   
     const startExplosion = (setExplodingState) => {
       setExplodingState(true);
       intervalRef.current = setInterval(() => {
         setExplodingState((prev) => !prev);
       }, 500); // Toggle every 2 seconds
     };
   
     const stopExplosion = () => {
       if (intervalRef.current) {
         clearInterval(intervalRef.current);
         intervalRef.current = null;
       }
     };
   
     const handleSmallExplosion = () => {
       startExplosion(setIsSmallExploding);
       setTimeout(stopExplosion, 500); // Stop after 2 seconds
     };
   
     useEffect(() => {
       startExplosion(setIsSmallExploding);
     
       return () => stopExplosion();
     }, []);
   
   
   
   
   
   
     const sendMessage = () => {
       const phoneNumber = "919164592716"; // Replace with the recipient's phone number (with country code, no + or spaces).
       const message = encodeURIComponent("Hello! I have a query about your services.");
       const url = `https://wa.me/${phoneNumber}?text=${message}`;
       window.open(url, "_blank");
     };
   














  var id ='a';
  const query = useQuery();
  
    
    
    
   if(query.get('id')){
    id=query.get('id');
   }
  


  const settings2 = {
    dots: true,            // Show navigation dots
    infinite: true,        // Infinite scrolling
    speed: 4000,            // Transition speed
    slidesToShow: 3,       // Show one slide at a time
    slidesToScroll: 1,     // Scroll one slide at a time
    autoplay: true,        // Autoplay slides
    autoplaySpeed: 500,  
    focusOnSelect:false,
    
    
    responsive: [
      {
        breakpoint: 1024,
        settings2: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
         
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
         
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
       
        }
      }
    ]// Autoplay speed in ms
  };
 
  var settings1 = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    focusOnSelect:false,
   
   
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
   
          dots: true
        }
      }]
  }


  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 5,
    centerMode: true, // Enable centered slides
    focusOnSelect: false, // Select a slide when clicked
    Arrow: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          initialSlide: 5,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 5,
          nextArrow: <SampleNextArrow1 />,
          prevArrow: <SamplePrevArrow1 />,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 5,
          nextArrow: <SampleNextArrow1 />,
          prevArrow: <SamplePrevArrow1 />,
        }
      }
    ]
  };

  const [loadTypes, setLoadTypes] = useState(() => {
    const savedLoadTypes1 = localStorage.getItem('loadTypes');

    return savedLoadTypes1 ? JSON.parse(savedLoadTypes1) : [];
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [targetword, settargetword] = useState('');

 



  const items = [
    { title: "Slide 1", description: "Description 1" },
    { title: "Slide 2", description: "Description 2" },
    { title: "Slide 3", description: "Description 3" },
    { title: "Slide 4", description: "Description 4" },
    { title: "Slide 5", description: "Description 5" },
  ];



  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {



        const response = await fetch('https://framefista.com/login1.php/load', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'

          },
        });


        const data = await response.json();
        
        setLoadTypes(data);
        localStorage.setItem('loadTypes', JSON.stringify(data));
        console.log("data  loaded ");
      } catch (error) {
        //alert("Internet Network weak error");
        const auth2= localStorage.getItem('loadTypes');
        
         if( auth2){
            
             const savedLoadTypes1 = localStorage.getItem('loadTypes');

             const data=savedLoadTypes1 ? JSON.parse(savedLoadTypes1) : [];
             setLoadTypes(data);
             console.log("loaded from storage ");
          }
          else{
            
            alert("Internet error");
          }
        
      }
    };
    fetchData();
  }, []);

  // Image carousel
  const images = [
    "/images/gift1.jpg",
    "/images/gift2.jpg",
    "/images/gift3.jpg",
    "/images/gift4.jpg",
    "/images/gift5.jpg",
    "/images/gift1.jpg"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [images.length]);

  // Locations data
  const loadlocation = [
    { id: 1, name: 'Pune', image: '/images/pune.jpg' },
    { id: 2, name: 'Mumbai', image: '/images/mumbai.jpg' },
    { id: 3, name: 'Ahemdabad', image: '/images/ahemdabad.jpg' },
    { id: 4, name: 'Shimla', image: '/images/shimla.jpg' },
    { id: 5, name: 'Srinagar', image: '/images/srinagar.jpg' },
    { id: 6, name: 'Delhi', image: '/images/delhi.jpg' }
  ];

  // Toggle functions
  const toggleMenu1 = () => setIsOpen1(!isOpen1);
  const toggleMenu2 = () => setIsOpen2(!isOpen2);

  const loadItems2 = loadTypes.slice(0, 6).map((type, i) => (
    <div key={i}  style={{ border: '0px solid black'}}class="ml-1 mr-1   " >
      <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
        <img class="ml-1 mr-1  "  src={type.image} alt={type.name} width="60%" height="210px" />
      </a>
      <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
        <h5 className="text-base font-base  text-gray-600 mt-0 mb-0 ml-5">{type.name}</h5>
        <h5 className="text-xl font-bold text-gray-600 mt-0 ml-5">{type.price}</h5>

      </a>
    </div>
  ));









  const product = loadTypes.map((type, i) => {
    const similarity = stringSimilarity.compareTwoStrings(type.name, targetword);
    const percentage = (similarity * 100).toFixed(2);

    return percentage > 50 ? (
      <div key={i} class="ml-1 mr-1   border-4 border-orange-600   " >
        <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
          <img clasa="ml-1 mr-1" src={type.image} alt={type.name} width="350px" height="250px" />
        </a>
        <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
          <h5 className="text-base font-base  text-gray-600 mt-0 mb-0 ml-5">{type.name}</h5>
          <h5 className="text-xl font-bold text-gray-600 mt-0 ml-5">{type.price}</h5>

        </a>
      </div>
    ) : null; // Return null if the percentage is below 70%
  });
  
  const product1=product.filter(element => element !== null);
  const product2 = loadTypes.map((type, i) => {
    const similarity = stringSimilarity.compareTwoStrings(type.id,id);
    const percentage = (similarity * 100).toFixed(2);

    return percentage > 99 ? (
      <div key={i} class="ml-1 mr-1   border-4 border-orange-600   " >
        <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
          <img clasa="ml-1 mr-1" src={type.image} alt={type.name} width="350px" height="250px" />
        </a>
        <a href={`/equipment?id=${encodeURIComponent(type.id)}`}>
          <h5 className="text-base font-base  text-gray-600 mt-0 mb-0 ml-5">{type.name}</h5>
          <h5 className="text-xl font-bold text-gray-600 mt-0 ml-5">{type.price}</h5>

        </a>
      </div>
    ) : null; // Return null if the percentage is below 70%
  });
  

  const loadlocationItems = loadlocation.map((location, i) => (
    <div key={i}>
      <p><img src={location.image} alt={location.name} /></p>
      <h5 style={{
        position: 'relative',
        top: '-35px',
        left: '10px',
        color: 'white',
        zIndex: '2',
        margin: '0',
        padding: '5px'
      }}>{location.name}</h5>
    </div>
  ));



  const handleModal1Change = (e) => {
    const { name, value } = e.target;
    settargetword(value);
  };
  const randomNumber = Math.floor(Math.random() * 11) + 1;
   
  return (

    <div className="am">







      <Navbar />
      <div className="imagealign">

         
      <div
    style={{
      position: 'absolute', // Absolute positioning for layering
      top: '100', // Adjust to desired position
      left: '100', // Align to the left
      zIndex: '2700', // Higher than the image
      width: '60px',
     
      padding: '10px',
    }}
  >
   
  </div>




        <img
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex}`}
          style={{ width: '100%', height: '240%', transition: 'opacity 0.5s', zIndex: "-1" }}
        />


      </div>
     
      <div className='imagegrid' style={{height:'Auto' ,background:'#f2efe380'}}>     
      {product}
      {id ? (
        <div className="flex flex-col items-center">
        {product2}
      </div>
        
      ) : (
       <h></h>
      )}
      </div>
      
     
      <h2 style={{ backgroundColor: 'fffdf5', text:'#3b3933'  }} className="text-lg">Popular innovative Products</h2>
    

      
     
 

      <div className='imagegrid1'  style={{   border: '1px solid white' }}>     
       {loadItems2}
      </div>

       
       <div  class="search-container1" style={{ zIndex: '0' }}>
         <input
           class="search-input1"
        
           placeholder='Search the product'
           type="text"
           name="search"
           value={targetword}
           onChange={handleModal1Change}
           required
         />
 
       </div>
       
      <h2 className="text-lg">Popular product </h2>
      <div style={{ backgroundColor: 'fffdf5', marginBottom: '10px' }}><Slider {...settings}>{loadItems2}</Slider></div>
     
      <div style={{ backgroundColor: 'fffdf5', borderRadius: '8px' , text:'#3b3933' }}>
        
    
      <button
        onClick={sendMessage}
        className=" fixed bottom-4 right-4 bg-green-500 text-white rounded-full p-1 shadow-lg hover:bg-green-600"
      >
   
        <FaWhatsapp className=" w-4 h-4" /> Click to Message us on WhatsApp
        {isSmallExploding && <ConfettiExplosion {...smallProps} />}
      </button>  

      <h2 className="text-lg">Popular product  location</h2>
        <h4 className="font-normal">Purchase our Product at Cheap Prices</h4>
      </div>
      <div className='imagegrid1'>{loadlocationItems}</div>

     

      <h2  style={{  text:'#3b3933'  }}>Customer Testimonials</h2>
      <div className="testimonials-slider">
    
      <Slider {...settings2}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-slide ">
                <div style={{  }}>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-gray-300">⭐</span>
                  <span className="ml-1 text-sm text-gray-500">{randomNumber} Reviews</span>
                </div>
            <p  className='mb-[20%]' >{testimonial.feedback}</p>
            <h4>- {testimonial.name}</h4>
          </div>
        ))}
      </Slider>
    </div>


      <h2  style={{  text:'#3b3933'  }}>Our Expertise:</h2>
      <div className="imagegrid2" >
        <div>
          <h4>Innovative Gifts:</h4>
          <p>Our curated selection of high-tech and personalized gifts is perfect for tech enthusiasts and trendsetters, offering unique experiences and lasting memories.</p>
        </div>
        <div>
          <h4>Smart Home Automation:</h4>
          <p>We bring convenience and intelligence to your home with our smart automation solutions, allowing you to control and optimize your environment seamlessly.</p>
        </div>
        <div>
          <h4>Pet Technology:</h4>
          <p>Our pet tech products, from smart feeders to GPS trackers, are designed to keep your pets happy, healthy, and secure while giving you peace of mind.</p>
        </div>
        <div>
          <h4>Advanced Robotics:</h4>
          <p>Our robotics innovations, from cleaning robots to personal assistants, are at the forefront of technology, transforming everyday tasks and industries alike.</p>
        </div>
      </div>

      <h2  style={{ text:'#3b3933'  }}>Why Choose Us:</h2>
      <div className="imagegrid2">
        <div>
          <h4>Unique and Thoughtful:</h4>
          <p>We focus on creating products that offer something extra, blending innovation with practicality to bring joy and utility into people’s lives.</p>
        </div>
        <div>
          <h4>Quality Engineering:</h4>
          <p>Our products are designed and tested by industry experts, ensuring they’re both high-quality and user-friendly for lasting performance.</p>
        </div>
        <div>
          <h4>Commitment to Pet Wellness:</h4>
          <p>We understand the bond between pet owners and their pets, creating technology that enhances pet care and strengthens this special relationship.</p>
        </div>
        <div>
          <h4>Customer-Focused Support:</h4>
          <p>Our support team provides dedicated assistance, ensuring you get the most out of our innovative products and solutions tailored to your lifestyle.</p>
        </div>
      </div>


      <center style={{ fontSize: '18px', marginTop: '10px' }}><b>Frequently Asked Questions:</b></center>
      <div className='imagegrid' style={{ padding: '20px', marginLeft: '5%', marginRight: '5%', display: 'flex', flexDirection: 'column', backgroundColor: '#fffdf5', borderRadius: '8px' }}>
        <div className="hamburger" onClick={toggleMenu1}>
          <p className='faq'>What is your speciality
            {isOpen1 ? <SlArrowUp /> : <SlArrowDown />}
          </p>
        </div>
        <ul className={`nav-links1 ${isOpen1 ? 'open' : ''}`}>

          <li><strong>Automatic Feeding:</strong> Ensures timely feeding of your pet, even when you're not home, providing convenience and peace of mind.</li>
          <li><strong>Portion Control:</strong> Helps regulate the amount of food to avoid overfeeding or underfeeding, promoting a healthier lifestyle for your pet.</li>
          <li><strong>Programmable Schedule:</strong> Customize feeding times to fit your pet’s routine, ensuring they maintain a consistent and balanced feeding schedule.</li>
          <li><strong>Durable and Safe:</strong> Built with pet-friendly, high-quality materials to ensure long-lasting use and safety.</li>
          <li><strong>Battery Backup:</strong> Provides reliable feeding even during power outages, so your pet never misses a meal.</li>


        </ul>

        <div className="hamburger" onClick={toggleMenu2}>
          <p className='faq'>List of services
            {isOpen2 ? <SlArrowUp /> : <SlArrowDown />}
          </p>
        </div>
        <ul className={`nav-links1 ${isOpen2 ? 'open' : ''}`} style={{ margin: '15px' }}>
          <li><strong>Innovative Gifts:</strong> Our curated selection of high-tech and personalized gifts is perfect for tech enthusiasts and trendsetters, offering unique experiences and lasting memories.</li>
          <li><strong>Smart Home Automation:</strong> We bring convenience and intelligence to your home with our smart automation solutions, allowing you to control and optimize your environment seamlessly.</li>
          <li><strong>Pet Technology:</strong> Our pet tech products, from smart feeders to GPS trackers, are designed to keep your pets happy, healthy, and secure while giving you peace of mind.</li>
          <li><strong>Advanced Robotics:</strong> Our robotics innovations, from cleaning robots to personal assistants, are at the forefront of technology, transforming everyday tasks and industries alike.</li>
        </ul>
      </div>





      <Footer class="relative" />

    </div>




  );
};

export default LandingPage;
