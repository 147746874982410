import React, { useState, useRef,useEffect} from 'react';
import Navbar from './nav';
import Footer from './footer';
import stringSimilarity from 'string-similarity';
const Cart = () => {
  const [formattedString, setFormattedString] = useState("");
 const [loadTypesArray1,setloadTypesArray1]  = useState([]);
 const [varid, setvarid] = useState(0);
 // Get data from localStorage
const savedLoadTypes = localStorage.getItem('loadTypes');

// Parse the JSON string back to an array (if it exists)
const loadTypesArray = savedLoadTypes ? JSON.parse(savedLoadTypes) : [];






  useEffect(() => {
    // Retrieve data from localStorage on component mount
    const savedLoadTypes1 = localStorage.getItem('loadTypes1');
    const loadTypesArray1 = savedLoadTypes1 ? JSON.parse(savedLoadTypes1) : [];
    setloadTypesArray1(loadTypesArray1)
    // Build the formatted string
    const formatted = loadTypesArray1
      .map(item => `p${item.product}q${item.quantity}`)
      .join(" ");

    // Set the formatted string in state
    setFormattedString(formatted);
  }, []); // Only run on mount    <div>{formattedString || "No items in cart"}</div>
 

  // Initialize with the starting price
 let price1 = 0;




function productlisting(id) {
  let image = null;
  loadTypesArray.forEach((type) => {
    const similarity = stringSimilarity.compareTwoStrings(type.id, id);
    const percentage = (similarity * 100).toFixed(2);
    if (percentage > 90) {
      image = type.image;
    }
  });
  return image;
}


function productlisting1(id) {
  let id22 = null;
  loadTypesArray.forEach((type) => {
    const similarity = stringSimilarity.compareTwoStrings(type.id, id);
    const percentage = (similarity * 100).toFixed(2);
    if (percentage > 90) {
      id22 = type.id;
    }
  });
  return id22;
}


function productlisting2(id) {
  let price22 = null;
  loadTypesArray.forEach((type) => {
    const similarity = stringSimilarity.compareTwoStrings(type.id, id);
    const percentage = (similarity * 100).toFixed(2);
    if (percentage > 90) {
      price22 = type.price;
    }
  });
  return price22;
}

// Create a lookup object for loadTypesArray to avoid nested loops

// Create a lookup object for loadTypesArray
const loadTypesLookup = loadTypesArray.reduce((acc, type) => {
  if (type && type.id !== undefined) {
    acc[type.id] = type;
  }
  return acc;
}, {});



// Iterate over loadTypesArray1 to calculate total price
loadTypesArray1.forEach((type1) => {
  if (!type1 || type1.product === undefined) {
    console.warn("Invalid type1:", type1);
    return;
  }
  
  const matchedType = Object.values(loadTypesLookup).find((type) => {
    if (!type || type.id === undefined) {
      console.warn("Invalid type in loadTypesLookup:", type);
      return false;
    }
   
    // Compare integers
    const similarity = stringSimilarity.compareTwoStrings(type1.product, type.id);
    const percentage = (similarity * 100).toFixed(2);
    console.log(`Comparing ${type1.id} with ${type.id}: ${percentage}% similarity`);
    return percentage > 90;
  });
  
  if (matchedType) {
    const priceString = matchedType.price || "Rs 0";
    const price = parseInt(priceString.replace(/^Rs/, '').trim(), 10);

    if (!isNaN(price)) {
      price1 += price*type1.quantity;
      console.log(`Added price from ${matchedType.id}: Rs ${price}`);
    } else {
      console.error(`Invalid price for ${matchedType.id}: ${priceString}`);
    }
  } else {
    console.warn(`No matching type found for ${type1.id}`);
  }
});

console.log("Final Total Price:", price1);



  return (
    
      <div  style={{background:"rgb(255,253,245)"}} className='justify-content pb-3 ' >
        <Navbar />
      <h1>Your Cart</h1>
       
      
   
      

      <div className='mb-5 lg:text-2xl  text-xl flex flex-col mb-3'>
        {loadTypesArray1.map((item, index) => (
        
          <div className='m-5 lg:text-2xl' key={index}>
             
               <div className='text-base font-semibold flex flex-col' >
                 <img class="w-[200px] h-[200px] justify-left" src={productlisting(item.product) || "default-image.png"}  />
               </div> 
               <p className=" m-0 font-normal text-2xl text-customGray ml-4"> {productlisting1(item.product) || 1}
              
               </p>

              <div className="flex flex-col p-1 mb-1  space-x-4 ml-4">

                <span className="text-sm font-medium text-gray-600">SKU: {productlisting1(item.product)}</span>


                <div style={{ marginLeft: "0px" }}>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-yellow-500">⭐</span>
                  <span className="text-gray-300">⭐</span>
                  <span className="ml-1 text-sm text-gray-500">(25 Reviews)</span>
                </div>
              </div>




               <label class="p-0 ml-4   text-customGray" htmlFor="quantity">Quantity: {item.quantity}</label>




                <div class=" relative flex flex-col h-[25px] lg:h-[70px] ml-4 lg:mb-2  mb-[30px] w-[70%]" >

               <div class=" relative flex flex-row  m-0 font-normal w-[22%] h-[35px]"><div><h4 class=" p-0 h-[15px] text-brownish-gray-500 font-extrabold"></h4></div><div><h4 class="font-normal p-[0px] h-[15px] text-2xl" > {productlisting2(item.product) || 1}</h4></div> </div>

                </div>
          </div>
      

        ))}
       
      </div>




      






     <h4 class="font-normal text-lg font-bold  text-gray-600 p-[0px] h-[15px] text-2xl lg:ml-[2%] ml-[7%]" >Total+delhivery= {parseInt(price1)+100}</h4>

      <a  className='mb-5 text-lg font-bold  text-gray-600 ml-[7%] mb-[50%]'   href="/request1">Checkout </a>
      <a  className='mb-5 text-lg font-bold  text-gray-600  ml-[7%] mb-[50%]'   href="/logout">Empty the cart </a>
      < div className="h-[300px]"></div>
      <Footer  className='mt-[500px] ' />
    </div>
  );
};

export default Cart;
