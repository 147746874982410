import React, { useState ,useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './log.css';
import Navbar from './nav';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AuthContext from './AuthContext';  

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  
  const { login } = useContext(AuthContext);
  
  const { logout} = useContext(AuthContext);


  const handleLogin =  async(e) => {
    e.preventDefault();
 
    const success = await login(phoneNumber, password);
    
    
    if (success) {
      
      navigate('/landing-page'); 
   
    } else {
      navigate('/login'); 
      const success1= await logout();
     
      alert('Login failed');
    }
    // Implement login logic here (e.g., API call)
   
    // Navigate to the home page after successful login
  };
  const value = localStorage.getItem('token');
    

  if( value){
   // alert("Allready logeed-in please logout to login again with new id")
    //navigate('/landing-page'); 
  }
  else{
    console.log(false);
  }

  return (




    

<div className='body12'>
<div class="container">
	<div class="screen">
		<div class="screen__content">


			<form class="login"   onSubmit={handleLogin} >
				<div class="login__field">
					<i class="login__icon fas fa-user"></i>
          <input
          class="login__input" 
            type="number" 
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
              placeholder='phone number'
            required 
          />
				</div>
				<div class="login__field">
					<i class="login__icon fas fa-lock"></i>
          <input 
          class="login__input" 
            type="password" 
            value={password} 
              placeholder='password'
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
				</div>
        <button  class="button login__submit"  type="submit">
					<span class="button__text">Log In Now</span>
					<i class="button__icon fas fa-chevron-right"></i>
				</button>				
			</form>
			<div class="social-login">
      <h3><a href="/signup">Signup</a></h3>
      <h3><a href="forgot-password">Forgot Password</a></h3>
				
			</div>
		</div>
		<div class="screen__background">
			<span class="screen__background__shape screen__background__shape4"></span>
			<span class="screen__background__shape screen__background__shape3"></span>		
			<span class="screen__background__shape screen__background__shape2"></span>
			<span class="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>
</div>

  );
}

export default Login;
