import React, { useState ,useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './log.css';
import axios from 'axios';
import AuthContext from './AuthContext';  
import '@fortawesome/fontawesome-free/css/all.min.css';
function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [otp, setotp] = useState('');
  const navigate = useNavigate();

  const { register } =  useContext(AuthContext);
  
  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    
    const success = await register(mobile, password,otp,email);
    console.log(success);
    if (success) {
      alert('Registration Success');
      navigate('/login'); 
      
    } else {
      
      alert('Registration failed');
    }
    // Implement signup logic here (e.g., API call)
    console.log('Signing up with', { mobile, password });
    // Navigate to login page after successful signup
  };

  const sendotp = async (e) => {

  if(email){
    const res = await axios.post('https://framefista.com/login1.php/auth/access/otp', { email});
    console.log(res);
      if(res.data.error){
        alert(res.data.error);
        return false
      }
      console.log(res.data);
      alert("otp generated");
    }
    else{
      alert("please insert email");

    }

  }

  return (
    






<div className='body12'>
<div class="container">
	<div class="screen">
		<div class="screen__content">


			<form class="login" style={{padding:'10px',paddingLeft:'30px',paddingRight:'30px'}}  onSubmit={handleSignup} >
				<div class="login__field">
					<i class="login__icon fas fa-user"></i>
          <input 
          placeholder='email'
           class="login__input" 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
				</div>
				<div class="login__field">
					<i class="login__icon fas fa-lock"></i>
          <input 
            placeholder='phone'
           class="login__input" 
            type="number" 
            value={mobile} 
            onChange={(e) => setMobile(e.target.value)} 
            required 
          />
				</div>

        <div class="login__field">
					<i class="login__icon fas fa-lock"></i>
         
          <input 
            placeholder='password'
           class="login__input" 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />

				</div>


        <div class="login__field">
					<i class="login__icon fas fa-lock"></i>
         
          <input 
            placeholder='confirm-password'
           class="login__input" 
            type="password" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
         
				</div>

        <div class="login__field">
					<i class="login__icon fas fa-lock"></i>
         
          <input 
            placeholder='otp'
           class="login__input" 
            type="number" 
            value={otp} 
            onChange={(e) => setotp(e.target.value)} 
            required 
          />
         
				</div>
      





        <button
    type="button"  // Use type="button" to avoid triggering form submission
    onClick={sendotp}  // onClick event to call the OTP generation function
  >
    Generate OTP
  </button>			





       
        <button  class="button login__submit"  type="submit">
					<span class="button__text">Sign Now</span>
					<i class="button__icon fas fa-chevron-right"></i>
				</button>				
			</form>
			<div class="social-login">
      <h3><a href="/login">Login</a></h3>
      <h3><a href="forgot-password">Forgot?</a></h3>
			
			</div>
		</div>
		<div class="screen__background">
			<span class="screen__background__shape screen__background__shape4"></span>
			<span class="screen__background__shape screen__background__shape3"></span>		
			<span class="screen__background__shape screen__background__shape2"></span>
			<span class="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>

</div>


  );
}

export default Signup;
