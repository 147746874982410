import React , { useState, useEffect } from 'react';
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/ForgotPassword';
import LandingPage from './components/LandingPage';
import Equipment from './components/equipment';
import Request from './components/Request';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './components/logout';
import OrderDetails from './components/order';
import OrderDetails1 from './components/order1';
import Cart from './components/cart';
import Request1 from './components/Request1';
import loadEquipment from './components/loadequipment';
import Slider from "react-slick";
import Dataupload from './components/dataupload';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import {
  isMobile,
  isTablet,
  isDesktop,
} from 'react-device-detect';





function App() {




  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {


      const auth2= localStorage.getItem('loadTypes');
        
      if( auth2){
       setIsLoading(false);
      }
      else{
      await loadEquipment(); // Wait for loadEquipment to finish
      setIsLoading(false); // Update loading state
      }
    };

    initialize();
  }, []);

  if (isLoading) {
    return <div class="text-3xl">Loading...</div>; // Show a loading screen while loading
  }
  return (
 
/* <Route element={<ProtectedRoute />}>
      <Route path="/landing-page" element={<LandingPage />} />
    </Route>*/


<AuthProvider>
<BrowserRouter>
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/landing-page" element={<LandingPage />} />
          <Route path="/dataupload" element={<Dataupload />} />
            <Route path="/equipment" element={<Equipment />} />
            <Route path="/request" element={<Request />} />
            <Route path="/order" element={<OrderDetails />} />
            <Route path="/order1" element={<OrderDetails1 />} />
            <Route path="/request1" element={<Request1 />} />
          <Route element={<ProtectedRoute />}>
          
          </Route>    
  </Routes>
  
</BrowserRouter>

</AuthProvider>





  );
}

export default App;
