import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './nav';
import Footer from './footer';
const Request1 = () => {
  const [modal1Data, setModal1Data] = useState({
    email: '',
    mobile: '',
    firstname: '',
    lastname: '',
    enddate: '',
    address: '',
    city: '',
    pin: '',
    companyName: '',
    position: '',
    orderid:  `${Date.now()}-${Math.floor(Math.random() * 1000000)}`

  });

  const [idArray, setIdArray] = useState([]);
  const [quantityArray, setQuantityArray] = useState([]);
  const navigate = useNavigate();

  // Step 1: Populate `idArray` and `quantityArray` from a single localStorage item
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('loadTypes1'));
    const column1=[];
    const column2=[];
    for (let i = 0; i < savedData.length; i++) {
      console.log(i);  // Outputs: 0, 1, 2, 3, 4
      column1.push(savedData[i].product);
      column2.push(savedData[i].quantity);
    }

    // Ensure the data is an array even if it's empty or undefined
    setIdArray(column1 || []);
    setQuantityArray(column2 || []);
    console.log(idArray);
    console.log(quantityArray);
  }, []);

  const handleModal1Change = (e) => {
    const { name, value } = e.target;
    setModal1Data((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitModal1 = (e) => {
    e.preventDefault();
    showLoadingAlert();

    const items = idArray.map((id, index) => ({
      id,
      quantity: quantityArray[index] || '', // Assign quantity or empty string if not available
    }));

    const dataToSubmit = {
      ...modal1Data,
      items, // Array of objects with id and quantity pairs
    };
    console.log(dataToSubmit);
    fetch('https://framefista.com/api1.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => response.json())
      .then((data) => {
        
        if (data.error) {
          alert(data.error);
        } else {
          alert('Order Submitted: ' + data.message);
          localStorage.removeItem('loadTypes1');
          navigate('/');
        }
      })
      .catch((error) => alert(error));
  };
 

  const showLoadingAlert = () => {
    const alertBox = document.getElementById('custom-alert');
    const form = document.getElementById('form1');
    alertBox.style.display = 'block';
    form.style.display = 'none';

    setTimeout(() => {
      alertBox.style.display = 'none';
      form.style.display = 'block';
    }, 3000);
  };

  return (
    <div  style={{background:"rgb(255,253,245)"}} >
       < Navbar  />
      
    <div className='imagegridrequest' class='w-[80%] mt-[12%] lg:mt-[5%] pt-[4%] ml-[5%] ' >
    <div>
    
    <p class="h-[50%] w-[100%]" id="custom-alert" style={{display:'None'}}>Loading...</p>
       
      <h3></h3>
      <form  id="form1" onSubmit={handleSubmitModal1}>
        <div className="imagegridrequest">

     
          <label>Address</label>
          <input className="login__input"
            type="text"
            name="email"
            value={modal1Data.email}
            onChange={handleModal1Change}
            required
          />
        </div>
        
        <div className="imagegridrequest">
          <label>Mobile</label>
          <input className="login__input"
            type="number"
            name="mobile"
            value={modal1Data.mobile}
            onChange={handleModal1Change}
            required
          />
        </div>
        <div className="imagegridrequest">
          <label>Name</label>
          <input className="login__input"
            type="text"
            name="firstname"
            value={modal1Data.firstname}
            onChange={handleModal1Change}
            required
          />           
        </div>


   
        <div className="imagegridrequest">
          <label>Address</label>
          <input className="login__input"
            type="text"
            name="address"
            value={modal1Data.address}
            onChange={handleModal1Change}
            required
          />
        </div>
        <div className="imagegridrequest">
          <label>City</label>
          <input className="login__input"
            type="text"
            name="city"
            value={modal1Data.city}
            onChange={handleModal1Change}
            required
          />
        </div>


        <div className="imagegridrequest">
          <label>Pin</label>
          <input className="login__input"
            type="number"
            name="pin"
            value={modal1Data.pin}
            onChange={handleModal1Change}
            required
          />

       
        </div>

        <div className="" class="flex flex-row   ml-[5%] lg:ml-[7%]">
          <label>Quantity :-</label>
          <label>{modal1Data.quantity}</label>

          </div>
          <h2>Currently only Support Cash on Delivery</h2>
        <input  class="w-[50%]" className="login__submit"  type="submit"/>

   

      </form>
    </div>
    
    </div>
    <Footer />
    </div>
  );
};

export default Request1;
