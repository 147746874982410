import React, { useState } from "react";
import axios from "axios";

const Dataupload = () => {
  const [formData, setFormData] = useState({
    name: "",
    details: "",
    load1: "",
    price: "",
    power: "",
  });

  const [images, setImages] = useState({
    image: null,
    image1: null,
    image2: null,
    image3: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setImages({ ...images, [name]: files[0] });
  };

  function showLoadingAlert() {
    const alertBox = document.getElementById('custom-alert');
    alertBox.style.display = 'block'; // Show the alert
   


    const alertBox1 = document.getElementById('form1');
    alertBox1.style.display = 'none'; // Show the alert
    setTimeout(() => {
        alertBox.style.display = 'none'; 
        alertBox1.style.display = 'block';// Hide the alert
    }, 3000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Combine form data and images into FormData for submission
    const data = new FormData();
    Object.keys(formData).forEach((key) => data.append(key, formData[key]));
    Object.keys(images).forEach((key) => {
      if (images[key]) {
        data.append(key, images[key]);
      }
    });
    showLoadingAlert();
    try {
      const response = await axios.post("https://framefista.com/load_types.php", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Load type added successfully!");
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error adding load type:", error);
      alert("Failed to add load type.");
    }
    

  };

  return (
    <div className='imagegridrequest' >
    <p class="h-[50%] w-[100%]" id="custom-alert" style={{display:'None'}}>Loading...</p>
    <form id="form1" onSubmit={handleSubmit} encType="multipart/form-data">
      <h2>Add Load Type</h2>
      <div>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Details:</label>
        <textarea
          name="details"
          value={formData.details}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div>
        <label>Load1:</label>
        <input
          type="text"
          name="load1"
          value={formData.load1}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Price:</label>
        <input
          type="text"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Power:</label>
        <input
          type="text"
          name="power"
          value={formData.power}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Primary Image:</label>
        <input
          type="file"
          name="image"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Additional Image 1:</label>
        <input
          type="file"
          name="image1"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Additional Image 2:</label>
        <input
          type="file"
          name="image2"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Additional Image 3:</label>
        <input
          type="file"
          name="image3"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
    </div>
  );
};

export default Dataupload;
