import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import "./log.css";
import "./log2.css";
import { FaBars, FaTimes, FaHome } from "react-icons/fa";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isButtonVisible1, setIsButtonVisible1] = useState(true);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const openModal1 = () => {
    setIsModalOpen1(true);
    setIsButtonVisible1(false);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
    setIsButtonVisible1(true);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const messages = [
    "Free Express Shipping across India on Orders above ₹1000/- (Applied @ Checkout)",
    "Flat 10% OFF on your first purchase! Use code: FIRST10",
    "Buy 2 Get 1 Free on selected items!",
    "Special discount for bulk orders. Contact us now!",
    "24/7 Customer Support. Call us anytime!",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        
       

        const response = await fetch('https://framefista.com/login1.php/load', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
           
          },
        });





        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        localStorage.setItem("loadTypes", JSON.stringify(data));
      } catch (error) {
        console.error("Fetch error:", error);
        //alert("Database error");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [messages.length]);

  const savedLoadTypes = localStorage.getItem("loadTypes");
  const loadTypes = savedLoadTypes ? JSON.parse(savedLoadTypes) : [];
  const loadItems2 = [];
  for (let i = 1; i < Math.min(loadTypes.length, 6); i++) {
    loadItems2.push(
      <div key={i} className="flex flex-row relative-z-9 ml-0">
       <div> <a href={`/equipment?id=${encodeURIComponent(loadTypes[i].id)}`}>
        {/*  <img
            className="w-15 h-16"
            src={loadTypes[i].image}
            alt={loadTypes[i].name}
          />*/}
        </a></div>
        <div>
        <h5 className="font-normal">{loadTypes[i].name}</h5>
        </div>
      </div>
    );
  }

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/landing-page");
  };

  return (
    <div>
      <div className="top-bar">
        <div className="message-container">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${index === currentMessageIndex ? "visible" : "hidden"
                }`}
            >
              {message}
            </div>
          ))}
        </div>
      </div>

      <header className="header">
        <div className="icons">
          <span className="icon" onClick={toggleMenu}>
            ☰
          </span>
          <div className="logo">
            <img src="/images/icon123.jpg" alt="Oxford Stationers Logo" />
          </div>
        </div>

        <div className="center"></div>

        <div className="icons">
          <a href="/login">
            <span id="login" name="login" className="icon">
              👤
            </span>
          </a>
          <a href="/cart">
            <span className="icon">🛒</span>
          </a>
          <a href="/order">
            <span className="icon">🛍️</span>
          </a>
          <a href="/">
            <FaHome className="icon" />
          </a>
        </div>
      </header>

      <div style={{ display: "flex", position: "fixed", zIndex: "1100" }}>
        <div ref={menuRef}>
        
          <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        {/*  <h3 className="font-medium " style={{ marginLeft: "-20px" }} >Pet Products</h3>
            <div className="imagegrid1-nav ml-[-20px]">{loadItems2}</div>*/}
            <h5 className="font-semibold"style={{ marginLeft: "-20px" }}>
              <a href="/login">Login</a>
            </h5>
            <h5 className="font-semibold" style={{ marginLeft: "-20px" }}>
              <a href="/order">Order</a>
            </h5>
            <h5  className="font-semibold" style={{ marginLeft: "-20px" }}>
              <a href="#contact">Contact</a>
            </h5>
            <h5 className="font-semibold" style={{ marginLeft: "-20px" }}>
              <a href="/">Home</a>
            </h5>
            <h5 className="font-semibold" style={{ marginLeft: "-20px" }}>
              <a href="/logout">Logout</a>
            </h5>
          </ul>
        </div>

        <Modal
          isOpen={isModalOpen1}
          onRequestClose={closeModal1}
          className="modal-nav"
          contentLabel="Modal 1"
          ariaHideApp={false}
        >
          <div className="relative-z-9 grid grid-cols-3 justify-items-center gap-10">
            {loadItems2}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Navbar;
